import React from "react"
import {
  Layout,
  FlexRow,
  Parallax,
  NewsletterSection,
} from "@components/layout"
import { AnimatedLink, Breadcrumbs } from "@components/shared"
import { BlogHero } from "@components/blog"
import { ProductItem, CategoryItem } from "@components/ecommerce"

import {
  wave,
  shop__wrapper,
  shop__category__wrapper,
  shop__products__wrapper,
} from "./styles/page.module.scss"

const renderWithAllProps = (Comp) => (entry, key) => (
  <Comp {...entry} key={key} />
)

const Shop = ({ pageContext, location }) => {
  const { page, products, categories } = pageContext
  const shuffledProducts = products.sort(() => Math.random() - 0.5)
  const shuffledCategories = categories.sort(() => Math.random() - 0.5)
  const translationUrls = {
    pl:`/pl/shop`,
    en:`/en/shop`
  }
  return (
    <Layout {...page[0]} translationUrl={translationUrls}>
      <BlogHero title={page.title} />
      <Breadcrumbs
        elements={[
          {
            label: "blog",
            url: "/blog/",
          },
        ]}
      />
      <div className={shop__wrapper}>
        <div className={shop__category__wrapper}>
          {shuffledCategories.map(renderWithAllProps(CategoryItem))}
        </div>

        <h4>Produkty w sklepie</h4>
        <div className={shop__products__wrapper}>
          {shuffledProducts.map(renderWithAllProps(ProductItem))}
        </div>
      </div>
    </Layout>
  )
}

export default Shop
